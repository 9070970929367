
// Libraries
import * as React from 'react'

// Services
import BallotService from '../../services/ballot'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import DuoSection from '../../components/duoSection'

// Context
import NotificationContext from '../../contexts/notification'

class WinnerDecisionPage extends React.Component {
	state = {
		isSubmitting: false,
		ballot: {}
	}

	static contextType = NotificationContext

	render() {
		const {ballot} = this.state

		const celebratoryWords = ['Yes', 'Hooray', 'Get in', 'Yesss', 'Woo', 'Woo-hoo', 'Yay', 'Three cheers', 'Yaaas']

		return <Layout className="page--success page--winner-decision nav-blue-half no-footer">
			<Seo title="Winners Decision" />
			<DuoSection success>
				<div>
					<div className="content-wrap">
						{ballot?.status_id === 7 && <>
							<h1>Unable to save your decision</h1>
							<p>Sorry, we were unable to save your chosen decision, please try again</p>
						</>}
						{ballot?.status_id === 2 && <>
							<h1>Ticket{ballot.tickets_requested > 1 ? 's' : ''} Confirmed</h1>
							<p>{celebratoryWords[Math.floor(Math.random() * celebratoryWords.length)]}! You've
								confirmed you're able to attend the event.  An email will be with you shortly
								explaining how to claim your tickets.  Have an amazing time at the event!</p>
						</>}
						{ballot?.status_id === 8 && <>
							<h1>Unable to attend</h1>
							<p>Sorry, that you're unable to attend this event.  We hope to see you at another event
								real soon!</p>
						</>}
						{(ballot.status_id && ![2, 7, 8].includes(ballot?.status_id)) && <>
							<h1>Finding ballot ...</h1>
							<p>Sorry, unable to make a decision relating to this ballot</p>
						</>}
						{!ballot.status_id && <>
							<h1>Working ...</h1>
							<p>Bear with us whilst we confirm your decision!</p>
						</>}
					</div>
				</div>
				<div></div>
			</DuoSection>
		</Layout>
	}

	componentDidMount() {
		const {addNotification} = this.context

		this.getBallot()
			.catch(() => addNotification('Ballot not found', 'error'))
	}

	getBallot = async () => {
		const ballotService = new BallotService()
		const {token, ballot_id, decision} = this.props
		const data = {
			'token': token,
			'ballot_id': ballot_id,
			'decision': decision
		};

		await ballotService.winnerDecision(data)
			.then((response) => {
				this.setState({
					ballot: response.data
				})
			})
	}
}

export default WinnerDecisionPage
